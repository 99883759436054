<template>
  <div>
    <div class="container max-w-screen-lg item-center mx-auto" id="news">
      <div class="pt-8 pb-16 px-16">
        <h3 class="text-center font-bold text-primary mb-5">Registration Event</h3>
        <div class="flex items-center">
          <input type="checkbox" class="w-5 h-5">
          <label class="ml-3 font-semibold">Use my data</label>
        </div>
        <div class="flex items-center mt-5">
          <p class="text-sm mr-5">Gender</p>
          <input type="radio" name="gender" class="w-5 h-5">
          <label class="ml-2 font-semibold mr-5">Male</label>
          <input type="radio" name="gender" class="w-5 h-5">
          <label class="ml-2 font-semibold">Female</label>
        </div>
        <div class="flex -mx-5">
          <div class="w-full mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Full Name<span>*</span></label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="Full Name">
          </div>
          <div class="w-full mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Identity Number NIK<span>*</span></label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="NIK Number">
          </div>
        </div>
        <div class="flex -mx-5">
          <div class="w-full lg:w-1/2 mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Phone Number<span>*</span></label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="Mobile Number">
          </div>
          <div class="w-full lg:w-1/2 mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Hobby</label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="Hobby">
          </div>
        </div>
        <div class="flex -mx-5">
          <div class="w-full lg:w-1/2 mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Religion<span>*</span></label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="Religion">
          </div>
          <div class="w-full lg:w-1/2 mt-3 px-5">
            <label class="text-xs font-semibold mb-2">Your Photo<span>*</span></label>
            <input type="text" class="w-full border border-b4 rounded-md py-2 px-5 text-b4" placeholder="Upload Your Photo">
          </div>
        </div>
        <div class="mt-12 flex justify-end">
        <button class="py-2 px-20 bg-primary hover:opacity-80 text-white text-xl font-semibold rounded-lg">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Slugify } from '@/utils';

export default {
  name: 'EventRegistration',
  components: {
  },
};
</script>
